.variantPopup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6); /* Darker overlay */
    z-index: 1000;
    box-sizing: border-box;
}

.variantPopupContent {
    background: #fff;
    border-radius: 0.75rem; /* Slightly larger border radius */
    width: 90%;
    max-width: 40rem;
    padding: 2rem; /* Increased padding */
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); /* Larger shadow */
    position: relative;
    display: flex;
    flex-direction: column;
    height: 85%; /* Increased height */
    max-height: 85vh;
    overflow-y: auto;
}

/* Styling for the item image */
.itemImage {
    width: 100%;
    height: auto;
    border-radius: 0.5rem; /* Rounded corners */
    margin-bottom: 1.5rem; /* Space below image */
}

.variantHeading {
    font-size: 1.75rem; /* Larger heading */
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 1.5rem; /* Space below heading */
}

.variantOptions {
    display: flex;
    flex-direction: column;
    gap: 1.25rem; /* Increased gap */
    flex: 1;
}

.variantOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0; /* Lighter border */
    padding-bottom: 1rem;
}

.variantName {
    font-size: 1.125rem;
    color: #444;
    font-weight: 500;
}

.priceContainer {
    display: flex;
    align-items: center;
    gap: 0.75rem; /* Adjust gap */
}

.originalPrice {
    font-size: 0.875rem;
    color: #999;
    text-decoration: line-through;
}

.discountPrice {
    font-size: 1.125rem;
    color: #e74c3c;
    font-weight: bold;
}

.quantityContainer {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.quantityDisplay {
    font-size: 1.125rem;
    color: #333;
    font-weight: bold;
}

.variantButton {
    background: #f5f5f5;
    border: none;
    border-radius: 0.25rem;
    padding: 0.75rem; /* Slightly larger buttons */
    cursor: pointer;
    transition: background 0.3s ease;
    font-size: 1.125rem;
}

.variantButton:hover {
    background: #ddd;
}

.closeButton {
    background: #e74c3c;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1.125rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-top: 1.5rem; /* Space above close button */
}

.closeButton:hover {
    background: #c0392b;
}
