.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cartContainer {
    background-color: #fff;
    border-radius: 10px;
    width: 90%;
    max-width: 800px;
    max-height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.3s ease-in-out;
}

@keyframes slideIn {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.cartHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.closeButton {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.closeButton:hover {
    color: #d9534f;
}

.orderStatus {
    background-color: #f0f8ff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.orderStatus p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.orderStatus button {
    background-color: #5bc0de;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.orderStatus button:hover {
    background-color: #31b0d5;
}

.orderStatus button:last-child {
    background-color: #d9534f;
}

.orderStatus button:last-child:hover {
    background-color: #c9302c;
}

.orderStatus div {
    display: flex;
    gap: 10px;
}


.addressSection {
    background-color: #eeeaea;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.addressSection h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}

.orderItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
}

.orderItem {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px 0;
    border-bottom: 1px solid #e0e0e0;
}

.orderItem:last-child {
    border-bottom: none;
}

.foodTypeIcon {
    width: 20px;
    height: 20px;
}

.itemInfo {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemInfo p {
    margin: 0;
}

.paymentDetails {
    background-color: #fdfdfd;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.paymentDetails div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.paymentDetails div:last-child {
    margin-bottom: 0;
    border-bottom: none;
}

.paymentDetails span {
    font-size: 16px;
    color: #333;
}

.totalAmount span {
    font-size: 18px;
    font-weight: bold;
    color: #d9534f;
}

/* Optional: Add a hover effect for better user interaction */
.paymentDetails div:hover {
    background-color: #f7f7f7;
}


.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
}

.popup {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.popup button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #d9534f;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.popup button:hover {
    background-color: #c9302c;
}

.popup button:last-child {
    background-color: #5bc0de;
}

.popup button:last-child:hover {
    background-color: #31b0d5;
}

.otpInput {
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 80%;
}

.otpError {
    color: red;
    margin-top: 5px;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
}

.userDropdown {
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

.additionalMessage {
    background-color: #f9f9f9;
    border-left: 4px solid #ffab00;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 4px;
    font-size: 1rem;
    color: #555;
}

.getDirectionsButton {
    background-color: #28a745;
    color: white;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.getDirectionsButton:hover {
    background-color: #218838;
}

