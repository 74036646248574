/* General Page Styling */
.orderSection {
    position: relative;
    text-align: center;
    color: white;
    background: url('../Images/myorders_backgroundimage.jpg') no-repeat center center;
    background-size: cover;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.orderOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.orderText {
    position: relative;
    font-size: 2rem;
    font-weight: bold;
}

.ordersContainer {
    padding: 20px;
    margin: auto;
}

/* Search Bar and Filter Icon */
.searchContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.searchBox {
    width: 100%;
    padding: 10px 0px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
}

.searchButton, .clearButton, .filterButton {
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #555;
}

.searchButton {
    right: 10px;
}

.clearButton {
    right: 40px;
}

.filterButton {
    right: 70px; /* Adjusted to add filter button in line with others */
}

.searchButton:focus, .clearButton:focus, .filterButton:focus {
    outline: none;
}

/* Filter Popup Overlay */
.filterPopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Filter Popup */
.filterPopup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
}

/* Close Button in Filter Popup */
.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

/* Filter Sections */
.filterSection {
    margin: 20px 0;
}

.filterSection p {
    font-weight: bold;
    margin-bottom: 10px;
}

.filterSection label {
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
}

.filterSection input[type="radio"] {
    margin-right: 10px;
}

/* Filter Actions (Clear All and Apply Buttons) */
.filterActions {
    display: flex;
    justify-content: space-between;
}

.filterActions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.filterActions button:first-child {
    background-color: #f44336;
    color: white;
}

.filterActions button:last-child {
    background-color: #4CAF50;
    color: white;
}

/* Order List and Cards */
.orderList {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
}

.orderCard {
    background: #fff;
    border: 1px solid #ffcc80;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.orderCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.orderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderHeader h1 {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}

.orderHeader h3 {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.orderStatus {
    background: rgb(217, 255, 217);
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.9em;
    color: #0d4300;
    margin-left: 10px;
    font-weight: bold;
}

.orderDetails {
    margin: 10px 0;
}

.orderItem {
    display: flex;
    align-items: center;
    margin: 5px 0;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.orderItem img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.orderItem p {
    margin: 0;
    font-weight: bold;
    color: #212121;
}

.orderFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.orderFooter p {
    font-weight: bold;
    font-size: 1.2em;
    color: #ff5722;
}

.orderDate {
    font-weight: 700;
    color: rgb(57, 49, 37);
}

.orderCard + .orderCard {
    margin-top: 20px;
}

/* Loader Spinner */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader .fa-spinner {
    font-size: 40px;
    color: #ffffff;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* OTP Styling */
.otp {
    color: rgb(0, 0, 0);
    font-weight: bold;
    background: rgb(255, 243, 199);
    border-radius: 5px;
    border: 1px solid;
    width: 81px;
    margin-top: 5px;
}

/* Pagination Styling */
.pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 10px;
}

.pageButton {
    background: none;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 8px 12px;
    margin: 0;
    cursor: pointer;
    font-size: 0.9rem;
    color: #333;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.pageButton.activePage {
    background-color: #ff5722;
    color: #fff;
}

.pageButton:focus {
    outline: none;
}

/* Disable Scrolling when Popup is Open */
.noScroll {
    overflow: hidden;
}
