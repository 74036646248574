.menuSection {
  position: relative;
  text-align: center;
  color: white;
  background: url('../Images/veg_munchurian.jpg') no-repeat center center;
  background-size: cover;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.categoryHeader {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  font-size: 1rem;
  color: black;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  justify-content: space-between;
}

.categoryHeader .categoryName {
  flex: 1;
  text-align: left;
}

.categoryHeader .chevronIcon {
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.menuText {
  position: relative;
  font-size: 2rem;
  font-weight: bold;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
}

.filters {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: -1px; /* Adjust this based on where you want it to stick */
  z-index: 998; /* Ensure it appears above other content */
  background-color: white; /* Prevent overlapping issues with background */
  padding: 0.5rem; /* Optional: Add some padding to make it look better when sticky */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add shadow when sticky */
}

.filters button {
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: 0.4rem;
  flex: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filters button.selected {
  background-color: rgb(255, 179, 0);
  color: whitesmoke;
}

.filters button.selectedVeg {
  background-color: green;
  color: whitesmoke;
}

.filters button.selectedNonVeg {
  background-color: red;
  color: whitesmoke;
}

.filters .searchBarContainer {
  display: flex;
  align-items: center; /* Center items vertically */
  position: relative;
}

.filters input.searchBar {
  padding: 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filters input.searchBar:focus {
  border-color: #ff4500; /* Highlight border color on focus */
  box-shadow: 0 0 5px rgba(255, 69, 0, 0.3); /* Subtle glow effect */
}

.filters .searchIcon {
  position: absolute;
  right: 1rem;
  color: #888;
  font-size: 1.2rem;
  pointer-events: none; /* Ensure the icon doesn't interfere with input */
}

.filters select.categorySelect {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.filters select.categorySelect:focus {
  border-color: #ff4500;
  box-shadow: 0 0 5px rgba(255, 69, 0, 0.3);
}

.noItemsFound {
  text-align: center;
  font-size: 1rem;
  color: #000000; /* Red color for the "No items found" message */
  padding: 2rem;
  margin-top: 2rem;
}

.category {
  margin-bottom: 0.5rem;
  flex: 1;
}

.category h2 {
  margin-bottom: 1rem;
  text-align: center;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: black;
}

.items {
  display: flex;
  flex-direction: column; /* Ensure one item per row */
  align-items: center; /* Center items horizontally */
  gap: 1rem; /* Spacing between rows */
  padding: 1rem; /* Padding around items container */
}

.itemCard {
  display: flex;
  flex-direction: row;
  border: 1px solid #ddd; /* Lighter border for a cleaner look */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Hide overflow for rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background-color: #fff; /* White background */
  width: 95vw; /* Full width for each card */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover effects */
  box-sizing: border-box; /* Include padding and border in height */
  position: relative;
}

.outOfStock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent overlay */
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  z-index: 10; /* Make sure it’s above other content */
  pointer-events: none; /* Disable interactions */
}

.itemCard.disabled {
  opacity: 1; /* Dim the card to show it’s disabled */
  pointer-events: none; /* Disable all interactions */
}

.itemCard:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.itemImage {
  width: 170px; /* Adjust width for mobile */
  height: 170px; /* Full height of the card */
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.itemInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute space evenly */
  padding: 0.5rem; /* Padding to ensure content fits */
  flex: 1; /* Takes remaining space */
  height: 100%; /* Full height of the card */
  box-sizing: border-box; /* Include padding in height */
}

.itemName {
  display: flex; /* Flex for better alignment */
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align text to the start */
  margin-bottom: 1px;
  overflow: hidden; /* Hide overflow */
}

.itemName h3 {
  margin: 0;
  color: #333; /* Darker text for readability */
  font-size: 1rem; /* Adjust font size if necessary */
}

.itemDescription {
  font-size: 0.8rem; /* Reduced size */
  color: #666;
  margin: 0.5px 0;
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Ellipsis for overflow text */
}

.foodTypeIcon {
  max-width: 1.2rem; /* Slightly smaller icon */
  max-height: 1.2rem;
  margin-right: 0.5rem;
}

.discountPrice {
  font-size: 1.2rem; /* Slightly smaller for better visual balance */
  color: #e63900; /* Use a more vibrant color for emphasis */
  font-weight: bold;
  margin-left: 0.5rem;
}

.originalPrice {
  font-size: 0.8rem; /* Adjusted for balance */
  color: #999;
  text-decoration: line-through;
}

.cartButton {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #ff4500;
  color: white;
  border: none;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  cursor: pointer;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.cartButton:hover {
  background-color: #e63900;
}

.cartButton .cartIcon {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.cartButton .cartIcon span {
  margin-right: 1rem;
}

.cartButton .cartText {
  display: flex;
  align-items: center;
}

.cartButton .cartText span {
  margin-left: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.quantitySelector {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.quantitySelector button {
  background-color: #ff4500;
  color: white;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.quantitySelector button:hover {
  background-color: #e63900;
}

.quantitySelector span {
  margin: 0 0.5rem;
  font-size: 1rem;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  z-index: 1010;
}

p {
  margin: 5px 0;
  color: #666;
}

.closeModal {
  background-color: #ff4500;
  color: white;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  align-self: flex-end;
}

.closeModal:hover {
  background-color: #e63900;
}

.closedPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.popupContent h2 {
  margin: 0;
  color: #ff4500;
}

.popupContent p {
  margin: 1rem 0;
}

.popupContent button {
  background-color: #ff4500;
  color: white;
  border: none;
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.popupContent button:hover {
  background-color: #e63900;
}

.noScroll {
  overflow: hidden;
}

/* Switch.module.css */

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  margin-right: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider - the toggle */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

/* Slider before (circle inside the switch) */
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: white;
  transition: .4s;
  top: 4px;
  left: 4px;
}

/* When the checkbox is checked, slide the slider */
input:checked+.slider {
  background-color: #4caf50;
}

/* Move the slider circle to the right */
input:checked+.slider:before {
  transform: translateX(14px);
}


@media (max-width: 768px) {
  .itemCard {
    height: auto; /* Auto height for flexibility */
    width: 90vw;
  }

  .itemImage {
    width: 150px; /* Smaller image for mobile */
    height: auto;
  }

  .itemName h3 {
    font-size: 1rem; /* Smaller font size for mobile */
  }

  .discountPrice {
    font-size: 1.2rem; /* Smaller discount price for mobile */
  }

  .originalPrice {
    font-size: 1rem; /* Smaller original price for mobile */
  }
}
