.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
        justify-content: space-between;
    }
}

.location, .contact {
    width: 100%;
    margin: 20px 0;
}

@media (min-width: 768px) {
    .location, .contact {
        width: 45%;
    }
}

h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

paragraph {
    font-size: 18px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    color: #666;
}

paragraph svg {
    margin-right: 10px;
    color: #ffdd00;
}

.direction {
    color: #d0021b;
    text-decoration: none;
    font-size: 18px;
}

.direction:hover {
    text-decoration: underline;
}

.contact a {
    color: #d0021b;
    text-decoration: none;
}

.contact a:hover {
    text-decoration: underline;
}

.socialMedia {
    margin-top: 10px;
    display: flex;
    gap: 15px;
}

.socialMedia a {
    color: #ffdd00;
    font-size: 24px;
    text-decoration: none;
}

.socialMedia a:hover {
    color: #d0021b;
}
