/* General styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.loginBox {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  position: relative;
}

.closeButton {
  position: absolute; /* Position the button absolutely */
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.iconHeader {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

p {
  margin: 10px 0;
  color: #666;
}

.inputGroup {
  display: flex;
  align-items: center;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.countryCode {
  background: #eee;
  padding: 10px;
  font-size: 16px;
  border-right: 1px solid #ccc;
}

.formInput {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  outline: none;
}

.submitButton {
  background: #ffca08;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.submitButton:hover {
  background: #ffd950;
}

.terms {
  font-size: 12px;
  color: #999;
}

.terms a {
  color: tomato;
  text-decoration: none;
}

.terms a:hover {
  text-decoration: underline;
}

/* Error message styles */
.error {
  color: #d9534f;
  /* Bootstrap red color or any other color for error */
  margin: 10px 0;
  font-size: 14px;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .loginBox {
    width: 95%;
  }
}
