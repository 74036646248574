.aboutSection {
    padding: 20px;
    background-color: #fff;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
  }
  
  .paragraph {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .subheading {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  
  @media (max-width: 768px) {
    .heading {
      font-size: 1.5rem;
    }
  
    .paragraph {
      font-size: 0.9rem;
    }
  
    .subheading {
      font-size: 1rem;
    }
  }
  