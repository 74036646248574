.section {
    background-color: #ff0000;
    color: #fff;
    padding: 10px 100px 50px;
    position: relative;
    text-align: center;
}

.reviewContainer {
    transition: transform 0.5s ease-in-out;
}

.reviewContainer h2{
    color: white;
    font-size: 50px;
}

.reviewText {
    font-size: 1.5em;
    margin-bottom: 10px;
    color : white
}

.reviewAuthor {
    font-size: 1.2em;
    color: #ffcd03;
}


@media (max-width: 768px) {
    .reviewContainer h2{
        color: white;
        font-size: 35px;
    }

    .reviewText {
        font-size: 1.2em;
    }

    .reviewAuthor {
        font-size: 2em;
    }

}

@media (max-width: 480px) {
    .reviewContainer h2{
        color: white;
        font-size: 25px;
    }

    .reviewText {
        font-size: 1em;
    }

    .reviewAuthor {
        font-size: 1em;
    }

    .navigation button {
        font-size: 1.2em;
    }
}
