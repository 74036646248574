.footer {
  background: no-repeat;
  background-color: #ffca08;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-family: 'Times New Roman', Times, serif;
  
}

.footerContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.fssaiContainer {
  background-color: #ffc800;
  padding: 10px 0;
  margin-bottom: 20px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif

}

.fssaiCode {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.footerSection {
  flex: 1;
  min-width: 250px;
  margin-bottom: 20px;
}

.footerSection h2 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footerSection p {
  margin: 5px 0;
  word-wrap: break-word;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.socialIcons i {
  font-size: 40px;
  color: black;
}

.footerNav {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  font-size: 1.25rem;
}

.footerNav a {
  text-decoration: none;
  color: black;
  border-right: 1px solid black;
  padding-right: 10px;
}

.footerNav a:last-child {
  border-right: none;
}

.footerBottom p {
  margin-top: 10px;
  font-size: 14px;
}