/* Home.module.css */
.fadeInScale {
    opacity: 0;
    transform: scale(0.9);
    animation: fadeInScale 1s forwards;
  }
  
  @keyframes fadeInScale {
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  


  /* Home.module.css */
.bounceIn {
    transform: translateY(-100px);
    opacity: 0;
    animation: bounceIn 1s forwards;
  }
  
  @keyframes bounceIn {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    50% {
      transform: translateY(20px);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  

  /* Home.module.css */
.rotateIn {
    transform: rotate(-10deg);
    opacity: 0;
    animation: rotateIn 1s forwards;
  }
  
  @keyframes rotateIn {
    to {
      transform: rotate(0);
      opacity: 1;
    }
  }

  
  /* Home.module.css */
.zoomIn {
    transform: scale(0.9);
    opacity: 0;
    animation: zoomIn 1s forwards;
  }
  
  @keyframes zoomIn {
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  
  /* Home.module.css */
.slideIn {
    transform: translateY(100%);
    opacity: 0;
    animation: slideIn 1s forwards;
  }
  
  @keyframes slideIn {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  