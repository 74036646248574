/* PageNotFound.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    padding: 20px;
    box-sizing: border-box;
}

.heading {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
}

.message {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 15px;
}

.link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.link:hover {
    text-decoration: underline;
}

/* Ensures the header and footer are styled consistently */
.header,
.footer {
    width: 100%;
}
