.section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5; /* Adjust the background color if needed */
}

.content {
    flex: 1;
    padding: 20px;
}

.heading {
    font-size: 5em;
    margin-bottom: 10px;
}

.subheading {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #555;
}

.text {
    margin-bottom: 20px;
    line-height: 1.6;
    font-size: 1.2em;
}

.button {
    background-color: #ff0000; /* Red color */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
}

.button:hover {
    background-color: #e60000; /* Darker red on hover */
}

.imageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    width: 35vw; /* Increase the image size */
}

@media (max-width: 768px) {
    .section {
        flex-direction: column;
    }

    .content {
        padding: 10px;
    }

    .imageContainer {
        padding: 10px;
    }

    .heading {
        font-size: 4em;
    }

    .button {
        font-size: 0.9em;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 3em;
    }

    .button {
        font-size: 0.8em;
        padding: 6px 12px;
    }

    .image {
        width: 60vw; /* Ensure the image fits smaller screens */
    }
}
