.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
        justify-content: space-between;
    }
}

.map {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .map {
        width: 60%;
        height: 400px;
        margin-bottom: 0;
    }
}

.info {
    background-color: #ffdd00;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
}

@media (min-width: 768px) {
    .info {
        width: 35%;
        text-align: left;
    }
}

.info h2 {
    margin-bottom: 10px;
    font-size: 24px;
}

.info p {
    margin: 5px 0;
    font-size: 18px;
}

.orderButton {
    background-color: #ff0000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 10px;
}

.orderButton:hover {
    background-color: #cc0000;
}
