.section2 {
    padding: 50px 0;
    background-color: #ffca08;
  }
  
  .container {
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .colMd6 {
    flex: 0 0 50%;
  }
  
  .heroImage {
    position: relative;
    overflow: hidden;
  }
  
  .heroImage img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 25px;
  }
  
  
  .heroContent {
    padding: 30px;
  }
  
  .heroContent h1 {
    font-size: 5rem;
    margin-bottom: 20px;
  }
  
  .heroContent p {
    font-size: 1.5rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: black;
  }
  
  .btn {
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 30px;
    background-color: #e30909;
    color: #fff;
    transition: background-color 0.3s ease;
    text-decoration: none;
  }
  
  .btn:hover {
    background-color: #020202;
  }
  
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  
    .heroImage {
      margin-bottom: 20px;
    }

    .heroContent h1 {
      font-size: 3rem;
    }
  
    .heroContent {
      padding: 15px;
    }
  }