.deliveryBoySection {
    position: relative;
    text-align: center;
    color: white;
    background: url('../Images/myorders_backgroundimage.jpg') no-repeat center center;
    background-size: cover;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deliveryBoyOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.deliveryBoyText {
    position: relative;
    font-size: 2rem;
    font-weight: bold;
}

.container {
    padding: 0px 5px;
    margin: 0px 5px;
    text-align: center; /* Center-align the content inside the container */
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding shadow for a lifted look */
    margin-bottom: 20px;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-size: 1rem;
    color: #333;
}

.table th {
    background-color: #4CAF50; /* Dark green background for headers */
    color: white; /* White text for better contrast */
    font-weight: bold;
    text-transform: uppercase;
}

.table tr:nth-child(even) {
    background-color: #f4f4f4; /* Light gray for even rows */
}

.table tr:hover {
    background-color: #e1e1e1; /* Slightly darker gray for hover effect */
}

.deleteButton {
    background: none;
    border: none;
    color: #FF5722; /* Orange color for delete button */
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.3s ease, transform 0.3s ease; /* Added transition for transform effect */
}

.deleteButton:hover {
    color: #D32F2F; /* Darker red color on hover */
    transform: scale(1.1); /* Slightly increase button size on hover */
}

/* New styles for the no results message */
.noResults {
    text-align: center;
    font-size: 1.5rem;
    color: #666; /* Slightly lighter gray for the no results message */
    padding: 20px;
    font-style: italic; /* Italicize the no results text */
}

.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Styles for the Add Button */
.addButtonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.addButton {
    margin-top: 10px;
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    gap: 10px; /* Space between the icon and text */
}

.addButton:hover {
    background-color: #218838;
}

/* Popup Styles */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure popup is above all content */
}

.popupContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.popupContent h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

.popupContent label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
}

.popupContent input {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.saveButton {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
}

.saveButton:hover {
    background-color: #218838;
}

.cancelButton {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
}

.cancelButton:hover {
    background-color: #c82333;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.popupIcon {
    display: block;
    margin: 0 auto 20px; /* Center-align icon with margin below */
    color: #28a745; /* Adjust color as needed */
}