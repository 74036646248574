.aboutSection {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px;
    background-color: #f9f4ef;
  }
  
  .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px;
  }
  
  .icon {
    font-size: 3rem;
    color: #ffc107;
    margin-bottom: 10px;
  }
  
  .label {
    font-size: 1.2rem;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .aboutSection {
      flex-direction: column;
      padding: 10px;
    }
  
    .iconContainer {
      margin: 10px 0;
    }
  
    .icon {
      font-size: 2.5rem;
    }
  
    .label {
      font-size: 1rem;
    }
  }
  