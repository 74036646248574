.couponCodeSection {
    position: relative;
    text-align: center;
    color: white;
    background: url('../Images/myorders_backgroundimage.jpg') no-repeat center center;
    background-size: cover;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.couponCodeOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.couponCodeText {
    position: relative;
    font-size: 2rem;
    font-weight: bold;
}

.container {
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;
}

.addButtonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.addButton {
    background-color: #fb2424;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.addButton:hover {
    background-color: #ff4646;
}

.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.noResults {
    text-align: center;
    font-size: 1.5rem;
    color: #666;
    padding: 20px;
    font-style: italic;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: left;
    position: relative;
}

.cardTitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: rgb(251, 34, 34);
}

.deleteButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #FF5722;
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}

.deleteButton:hover {
    color: #E64A19;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Darker overlay for better contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popupContent {
    background: white;
    padding: 20px;
    border-radius: 10px; /* More rounded corners */
    width: 80%;
    max-width: 600px; /* Slightly larger width */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow for depth */
    position: relative;
    text-align: left; /* Align text to the left for better readability */
    animation: fadeIn 0.3s ease-out; /* Fade-in animation for a smooth appearance */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.popupContent h2 {
    margin-bottom: 20px;
    font-size: 1.75rem; /* Slightly larger font size for the title */
    color: #333; /* Darker color for better contrast */
}

.popupContent label {
    display: block;
    margin-bottom: 8px;
    font-size: 1rem; /* Increased font size for labels */
    color: #666; /* Slightly darker color for labels */
}

.popupContent input {
    width: calc(100% - 16px); /* Adjust width to account for padding */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem; /* Slightly larger font size */
    box-sizing: border-box; /* Include padding in width calculation */
}

.saveButton, .cancelButton {
    padding: 12px 24px; /* Larger padding for buttons */
    font-size: 1.1rem; /* Slightly larger font size */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Added transform transition */
    margin: 5px;
}

.saveButton {
    background-color: #4CAF50;
    color: white;
}

.saveButton:hover {
    background-color: #45a049;
    transform: scale(1.02); /* Slightly larger on hover */
}

.cancelButton {
    background-color: #f44336;
    color: white;
}

.cancelButton:hover {
    background-color: #e53935;
    transform: scale(1.02); /* Slightly larger on hover */
}

.error {
    color: red;
    font-size: 0.9rem;
    margin-top: -10px; /* Adjusted margin to align error messages better */
    margin-bottom: 10px;
}
