.section {
    background-image: url('../../Images/veg_paneer_tikka.jpg'); /* Use the correct path to your image */
    background-size: cover;
    background-position: center;
    height: 400px; /* Adjust the height as needed */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    padding: 20px;
    text-align: center;
    color: white;
    border-radius: 10px;
}

.heading {
    font-size: 2em;
    margin-bottom: 20px;
    color: #fff;
}

.button {
    background-color: #ff0000; /* Red color */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
}

.button:hover {
    background-color: #e60000; /* Darker red on hover */
}

@media (max-width: 768px) {
    .heading {
        font-size: 1.5em;
    }

    .button {
        font-size: 0.9em;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .heading {
        font-size: 1.2em;
    }

    .button {
        font-size: 0.8em;
        padding: 6px 12px;
    }
}
