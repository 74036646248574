.section1 {
    position: relative;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    font-family: Arial, sans-serif;
}

.heroImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    transition: opacity 0.5s ease-in-out;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.orderButton {
    background-color: #e30909;
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-color: #e30909;
}

.orderButton:hover {
    background-color: darkred;
}

.socialMedia {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.socialMedia a {
    margin-bottom: 50px;
    color: white;
    text-decoration: none;
    transform: rotate(-90deg);
    font-size: 0.875rem;
}

@media (max-width: 768px) {
    h1 {
        font-size: 1.75rem;
    }

    .orderButton {
        padding: 8px 16px;
        font-size: 0.875rem;
    }

    .socialMedia {
        right: 5px;
        bottom: 5px;
    }

    .socialMedia a {
        font-size: 0.75rem;
    }
}
